export default class PreloadScene extends Phaser.Scene {
	private assetsPath = 'assets/game-resources/';
	constructor() {
		super({ key: 'PreloadScene' });
	}

	preload() {
		const level = this.game.registry.get('level');

		// this.load.image('tile', 'assets/img/tile.png');
		this.load.image('backwards', this.assetsPath + 'left-pedal.png');
		this.load.image('gas', this.assetsPath + 'right-pedal.png');
		this.load.image('car', this.assetsPath + 'car.png');
		this.load.image('wheel', this.assetsPath + 'wheel.png');
		this.load.image('restart-btn', this.assetsPath + 'restart-btn.png');
		this.load.image('restart-bg', this.assetsPath + 'restart-bg.png');
		this.load.image('arrow', this.assetsPath + 'speed-arrow.png');
		this.load.image('speed', this.assetsPath + 'speed.png');
		this.load.image('progress-bg', this.assetsPath + 'progress-bg.png');
		this.load.image('progress-icon', this.assetsPath + 'progress-icon.svg');
		this.load.image('road', this.assetsPath + `levels/road-bg-${level}.png`);
		// this.load.image('level', this.assetsPath + `levels/level-bg-${level}.png`);
		this.load.image(
			'level',
			this.assetsPath + `levels/level-bg-${level}-full.png`
		);
		this.load.image('coin', this.assetsPath + `fonbet-coin.svg`);
		this.load.image('counter-bg', this.assetsPath + `counter-bg.svg`);
		this.load.image('time-bg', this.assetsPath + `time-bg.png`);
		this.load.image('time', this.assetsPath + `time.svg`);
		// this.load.image('wholes-small', 'assets/img/wholes-small.png');
		// this.load.image('grass', 'assets/img/grass.png');
		// this.load.image('background', 'assets/img/background.png');
		// this.load.multiatlas('atlas', 'assets/textureAtlas.json', 'assets')
	}

	create() {
		// this.scene.start('BackgroundScene');
		this.scene.start('MainScene');
		this.scene.start('GuiScene');
	}
}
