import { ElementRef, inject, Injectable } from '@angular/core';
import { Game } from 'phaser';
import { gameConfig } from '@src/app/ts/models/game';
import { ControlsService } from '@services/control.service';
import { getRoadSprite } from '@interfaces/level-config.interface';
import { Router } from '@angular/router';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { GameNames } from '@enums/game.enum';
import { LevelFinish } from '@interfaces/level-finish.interface';
import { StateService } from '@services/state.service';

@Injectable({
	providedIn: 'root',
})
export class GameService {
	private game: Game;
	private control: ControlsService = inject(ControlsService);
	private route: Router = inject(Router);
	private modal: ModalService = inject(ModalService);
	private state: StateService = inject(StateService);
	private config = gameConfig;
	private _container: HTMLElement;
	private level: number;
	set container(container: ElementRef) {
		this._container = container.nativeElement;
	}

	get container(): HTMLElement {
		return this._container;
	}
	constructor() {}

	public prepareGame() {
		if (this.game) {
			this.game.destroy(true);
		}
		const level = this.state.levelId;
		this.game = new Game({
			...this.config,
			scale: {
				...this.config,
				width: this.container.offsetWidth,
				height: this.container.offsetHeight,
			},
			callbacks: {
				postBoot: () => {
					if (level === 1) {
						setTimeout(() => this.game.pause(), 220);
					}
				},
			},
		});
		const sprite = getRoadSprite({ level });
		this.game.registry.set(GameNames.SPRITE, sprite);
		this.game.registry.set(GameNames.LEVEL, level);
		if (level === 1) {
			this.modal.openModal(ModalType.GAME_ONBOARDING, true, {
				data: this.game,
			});
			// setTimeout(() => this.game.pause(), 300);
		} else {
			this.modal.closeModal();
		}
		this.eventsListener();
	}

	private eventsListener() {
		this.game.events.on('start', () => {
			console.log('start');
		});
		this.game.events.on('restart', () => {
			console.log('restart');
			this.modal.openModal(ModalType.PAUSE, true, { data: this.game });
			const mainScene = this.game.scene.getScene('MainScene');
			const guiScene = this.game.scene.getScene('GuiScene');
			mainScene.game.pause();
			guiScene.game.pause();
		});

		this.game.events.once('finish', () => {
			console.log('finish');
			this.game.pause();
			const elapsedTime =
				Number(this.game.registry.get(GameNames.ELAPSED_TIME)) < 0
					? 0
					: Number(this.game.registry.get(GameNames.ELAPSED_TIME));
			const time = this.game.registry.get(GameNames.TIME);
			const coins = this.game.registry.get(GameNames.COINS);
			const score = 500 + coins * 100 + elapsedTime;
			const data: LevelFinish = {
				game: this.game,
				elapsedTime,
				coins,
				score,
				time,
			};
			this.modal.openModal(ModalType.LEVEL_FINISHED, true, {
				data: data,
			});
		});
	}

	public backwards() {}

	public resumeGame() {
		this.game.resume();
	}
}
