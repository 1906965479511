import { Component, inject, Input } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import { ModalType } from '@modal/ts/modal-type.enum';
import { Game } from 'phaser';
import { StateService } from '@services/state.service';
import { GameService } from '@services/game.service';
import { LevelFinish } from '@interfaces/level-finish.interface';

@Component({
	selector: 'app-modal-level-finish',
	templateUrl: './modal-level-finish.component.html',
	styleUrl: './modal-level-finish.component.scss',
	standalone: true,
})
export class ModalLevelFinishComponent extends ModalBaseComponent {
	@Input() data: LevelFinish;
	private state = inject(StateService);
	private gameService = inject(GameService);

	nextLevel() {
		this.data.game.destroy(true);
		this.state.incrementLevelId();
		this.closeAndNavigate('/main');
	}

	restart() {
		this.gameService.prepareGame();
	}
}
