<div class="page save-page">
  <img class="page__cloud" src="./assets/images/main-cloud.png" alt="cloud">
  <div class="page__busWrapper">
    <img class="page__bus" src="./assets/images/bus.png" alt="bus">
    <div class="page__pointer">
      <span>Конец</span>
    </div>
  </div>
  <div class="page__dark">
    <img class="page__smallDecor" src="./assets/images/small-decor.png" alt="decor">
    <div class="save-page__title">Фиксируем результат</div>
    <div class="save-page__info">
      <div class="save-page__info-item">
        <div class="save-page__info-col _left">Моя сумма очков:</div>
        <div class="save-page__info-col">20002</div>
      </div>
      <div class="save-page__info-item">
        <div class="save-page__info-col _left">Рекорд болельщиков:</div>
        <div class="save-page__info-col">2000000</div>
      </div>
      <div class="save-page__info-item">
        <div class="save-page__info-col _left">Позиция:</div>
        <div class="save-page__info-col">#2334456 из #2564233</div>
      </div>
    </div>
    <div class="save-page__btns">
      <a class="btn _white _large" routerLink="/main">Вернуться к игре</a>
      <a class="btn _red _large _fz16" routerLink="/save-form">Зафиксировать результат!</a>
    </div>
    <div class="save-page__text">Внимание, после фиксации результата ваш текущий прогресс сбросится и вам потребуется проходить игру заново! </div>
  </div>
  <div class="fonbet">
    <a class="fonbet__logo" href="#">
      <img src="./assets/images/fonbet-2.svg" alt="logo">
    </a>
    <div class="fonbet__flex">
      <button class="fonbet__gift"><img src="./assets/images/gift.svg" alt="logo"></button>
      <button class="fonbet__question"><img src="./assets/images/question.svg" alt="logo"></button>
    </div>
  </div>
</div>

