// Coin.ts
import Phaser from 'phaser';

export default class Coin {
	private scene: Phaser.Scene;
	public sprite: Phaser.Physics.Matter.Sprite;
	private deltaY: number; // Смещение по Y над дорогой
	private tween: Phaser.Tweens.Tween; // Ссылка на Tween
	private rotationTween: Phaser.Tweens.Tween; // Ссылка на Tween для вращения
	private isCollected: boolean = false; // Флаг, указывающий, собрана ли монетка

	constructor(scene: Phaser.Scene, x: number, y: number, deltaY: number) {
		this.scene = scene;
		this.deltaY = deltaY;

		// Создание Matter.js спрайта монетки с физическими свойствами
		this.sprite = this.scene.matter.add.sprite(
			x,
			y - this.deltaY,
			'coin',
			null,
			{
				isSensor: true, // Монетка как сенсор для триггеров столкновений
				restitution: 0.5, // Отскок
				friction: 0.1, // Сопротивление
				ignoreGravity: true,
			}
		);
		this.sprite.setOrigin(0.5, 0.5);
		this.sprite.setDisplaySize(100, 100); // Настройте размер по необходимости

		// Добавление вращения монетки для визуального эффекта
		this.rotationTween = this.scene.tweens.add({
			targets: this.sprite,
			angle: 360,
			duration: 2000,
			repeat: -1,
		});
	}

	/**
	 * Удаляет монетку из сцены с анимацией полёта вверх и исчезания
	 */
	public collect(): void {
		if (this.isCollected) return; // Предотвращение многократного вызова
		this.isCollected = true;

		// Останавливаем вращение монетки
		this.rotationTween.stop();

		// Останавливаем физические взаимодействия
		this.sprite.setSensor(true);
		this.sprite.setStatic(true);

		// Добавляем анимацию полёта вверх и исчезания
		this.scene.tweens.add({
			targets: this.sprite,
			y: this.sprite.y - 50, // Поднимаем монетку на 50 пикселей
			alpha: 0, // Плавно делаем монетку прозрачной
			duration: 500,
			ease: 'Power1',
			onComplete: () => {
				this.sprite.destroy(); // Удаляем спрайт после завершения анимации
			},
		});
	}
}
