import GameConfig = Phaser.Types.Core.GameConfig;
import PreloadScene from '@src/app/ts/scenes/preload-scene';
import MainScene from '@src/app/ts/scenes/main-scene';
import GuiScene from '@src/app/ts/scenes/gui-scene';

export const gameConfig: GameConfig = {
	parent: 'game-container', // Убедитесь, что ID соответствует вашему контейнеру
	backgroundColor: '#fff',
	scale: {
		mode: Phaser.Scale.FIT,
		autoCenter: Phaser.Scale.CENTER_BOTH,
		width: 375,
		height: 667,
	},
	scene: [PreloadScene, MainScene, GuiScene],
	physics: {
		default: 'matter',
		matter: {
			gravity: {
				x: 0,
				y: 0.9,
			},
			// debug: {
			// 	boundsColor: 0x000000,
			// 	showBounds: true,
			// 	showStaticBody: true, // Показывать статические тела
			// 	showVelocity: true,
			// 	showCollisions: true,
			// 	showBody: true,
			// },
		},
	},
};
