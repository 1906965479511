import { Routes } from '@angular/router';
import { StartPageComponent } from '@pages/start-page/start-page.component';
import { OnboardingPageComponent } from '@pages/onboarding-page/onboarding-page.component';
import { MainPageComponent } from '@pages/main-page/main-page.component';
import { GamePageComponent } from '@pages/game-page/game-page.component';
import { SavePageComponent } from '@pages/save-page/save-page.component';
import { SaveFormPageComponent } from '@pages/save-form-page/save-form-page.component';
import { RegistrationPageComponent } from '@pages/registration-page/registration-page.component';
import { CongratulationsPageComponent } from '@pages/congratulations-page/congratulations-page.component';

export const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: StartPageComponent,
	},
	{
		path: 'onboarding',
		component: OnboardingPageComponent,
		data: { animation: 'onboarding' },
		// canActivate: [authGuard, onboardingGuard],
		// resolve: {
		//   slides: onboardingItemsResolver,
		// },
	},
	{
		path: 'main',
		component: MainPageComponent,
		data: { animation: 'main' },
		// canActivate: [authGuard],
		// resolve: {
		//   user: userResolver,
		// },
	},
	// {
	// 	path: 'game',
	// 	component: GamePageComponent,
	// 	data: { animation: 'game' },
	// 	// canActivate: [authGuard],
	// 	// resolve: {
	// 	//   user: userResolver,
	// 	// },
	// },
	{
		path: 'game/:level',
		component: GamePageComponent,
		data: { animation: 'game' },
	},
	// Другие маршруты
	{
		path: 'save',
		component: SavePageComponent,
		data: { animation: 'save' },
		// resolve: { faqItems: faqItemsResolver },
		// canActivate: [authGuard],
	},
	{
		path: 'save-form',
		component: SaveFormPageComponent,
		data: { animation: 'save-form' },
		// resolve: { faqItems: faqItemsResolver },
		// canActivate: [authGuard],
	},
	{
		path: 'registration',
		component: RegistrationPageComponent,
		data: { animation: 'registration' },
		// resolve: { faqItems: faqItemsResolver },
		// canActivate: [authGuard],
	},
	{
		path: 'congratulations',
		component: CongratulationsPageComponent,
		data: { animation: 'congratulations' },
		// resolve: { faqItems: faqItemsResolver },
		// canActivate: [authGuard],
	},
	{
		path: '**',
		redirectTo: '',
	},
];
