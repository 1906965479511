import { Component, Input } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';

@Component({
	selector: 'app-modal-level-finish',
	templateUrl: './modal-all-levels-complete.component.html',
	styleUrl: './modal-all-levels-complete.component.scss',
	standalone: true,
})
export class ModalAllLevelsCompleteComponent extends ModalBaseComponent {
	closeFinishModal() {}
}
