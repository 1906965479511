import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	inject,
	OnInit,
	signal,
	TemplateRef,
	ViewChild,
	WritableSignal,
} from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { Router, RouterLink } from '@angular/router';

@Component({
	selector: 'app-onboarding-page',
	standalone: true,
	imports: [NgTemplateOutlet, SwiperDirective, NgOptimizedImage, RouterLink],
	templateUrl: './onboarding-page.component.html',
	styleUrl: './onboarding-page.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingPageComponent implements OnInit {
	@ViewChild('firstSlide', { static: true }) firstSlide: TemplateRef<any>;
	@ViewChild('secondSlide', { static: true }) secondSlide: TemplateRef<any>;
	@ViewChild('thirdSlide', { static: true }) thirdSlide: TemplateRef<any>;
	public slides: string[] = [
		'./assets/images/onboarding-image.png',
		'./assets/images/onboarding-image.png',
		'./assets/images/onboarding-image.png',
	];
	public currentTemplate: WritableSignal<TemplateRef<any>> = signal(null);
	public config: SwiperOptions = {
		pagination: true,
	};
	private templates: TemplateRef<any>[] = [];
	private currentIndex: number = 0;

	public ngOnInit() {
		this.templates = [this.firstSlide, this.secondSlide, this.thirdSlide];
		this.currentTemplate.set(this.templates[this.currentIndex]);
	}

	public nextTemplate() {
		if (this.currentIndex === this.templates.length - 1) {
			this.currentIndex = 0;
		} else {
			this.currentIndex = (this.currentIndex + 1) % this.templates.length;
		}
		this.currentTemplate.set(this.templates[this.currentIndex]);
	}
}
