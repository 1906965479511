import { Component, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
	animate,
	query,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { SmartModalContainerComponent } from '@modal/components/smart-modal-container/smart-modal-container.component';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, SmartModalContainerComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	animations: [
		trigger('routeAnimations', [
			transition('* <=> *', [
				style({ position: 'relative' }),
				query(
					':enter, :leave',
					[
						style({
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: 0,
							width: '100%',
							height: '100%',
						}),
					],
					{ optional: true }
				),
				query(':enter', [style({ opacity: 0 })], { optional: true }),
				query(
					':leave',
					[style({ opacity: 1 }), animate('.3s', style({ opacity: 0 }))],
					{ optional: true }
				),
				query(':enter', [animate('.3s', style({ opacity: 1 }))], {
					optional: true,
				}),
			]),
		]),
	],
})
export class AppComponent {
	public maxHeight: number;
	public maxWidth: number;
	public isDesktop: boolean = false;

	constructor() {
		this.updateSize();
	}

	@HostListener('window:resize', ['$event'])
	public onResize() {
		this.updateSize();
	}

	public prepareRoute(outlet: RouterOutlet) {
		return {
			value:
				outlet &&
				outlet.activatedRouteData &&
				outlet.activatedRouteData['animation'],
			params: { padding: this.isDesktop ? '7px 0px' : '0px' },
		};
	}

	private updateSize(): void {
		this.isDesktop = window.innerWidth > 585;
		this.maxHeight = window.innerHeight;
		this.maxWidth = window.innerWidth;
	}
}
