import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-save-page',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './save-page.component.html',
	styleUrl: './save-page.component.scss',
})
export class SavePageComponent {}
