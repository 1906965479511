<div class="onboarding-modal">
	<div class="onboarding-modal__inner">
		<swiper-container init="false" appSwiper (slideIndex)="changeIndex($event)">
			@for (slide of slides; track slide.id) {
				<swiper-slide>
					<div class="onboarding-modal__slide">
						<div class="onboarding-modal__image">
							<img
								[ngSrc]="slide.image"
								alt="image"
								width="320"
								height="232"
								priority />
						</div>
						<img
							class="onboarding-modal__decor"
							src="./assets/images/onboarding-decor.png"
							alt="decor" />
						<p
							class="onboarding-modal__text"
							[innerHTML]="slide.description"></p>
					</div>
				</swiper-slide>
			}
		</swiper-container>
		<div class="onboarding-modal__btns">
			<button
				class="btn _small _black"
				id="slide-prev"
				[ngClass]="{ hide: slideIndex() === 0 }"
				>Назад</button
			>
			<button class="btn _small _red" (click)="navigateTo()">
				@if (slideIndex() === 2) {
					Погнали
				} @else if (slideIndex() === 1) {
					Окей
				} @else {
					Хорошо
				}
			</button>
		</div>
	</div>
</div>
