import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ControlsService {
	private leftPress = new Subject<void>();
	private leftRelease = new Subject<void>();
	private rightPress = new Subject<void>();
	private rightRelease = new Subject<void>();

	leftPress$ = this.leftPress.asObservable();
	leftRelease$ = this.leftRelease.asObservable();
	rightPress$ = this.rightPress.asObservable();
	rightRelease$ = this.rightRelease.asObservable();

	pressLeft() {
		this.leftPress.next();
	}

	releaseLeft() {
		this.leftRelease.next();
	}

	pressRight() {
		this.rightPress.next();
	}

	releaseRight() {
		this.rightRelease.next();
	}
}
