import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {ErrorHandlerService} from "./error-handler.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _token: string;
  constructor(
    private http: HttpClient,
    private error: ErrorHandlerService
  ) {}

  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }
}
