<div class="onboarding">
  <ng-container *ngTemplateOutlet="currentTemplate()"></ng-container>

  <ng-template #firstSlide>
    <div class="onboarding__slider">
      <swiper-container init="false" appSwiper [extendConfig]="config">
        @for (slide of slides; track slide) {
        <swiper-slide>
          <div class="onboarding__slide">
            <div class="onboarding__image">
              <img
                [ngSrc]="slide"
                alt="image"
                width="280"
                height="280"
                priority />
            </div>
          </div>
        </swiper-slide>
        }
      </swiper-container>
      <button class="slider-btn _prev" id="slidePrev"></button>
      <button class="slider-btn" id="slideNext"></button>
    </div>
    <div class="onboarding__dark">
      <img class="onboarding__decor" src="./assets/images/onboarding-decor.png" alt="decor">
      <p class="onboarding__text"> <strong>Привет, болельщик ФК «Акрон»!</strong> <br><br>
        Наш ретро-автобус <strong>ЛамПАЗ</strong> отправляется в путь, чтобы доставить команду на поле, но он не сможет добраться без твоей помощи... Бери управление ЛамПАЗом в свои руки! Собирай монеты и доставь любимый клуб до стадионов как можно быстрее!
      </p>
      <button class="btn _red _medium" (click)="nextTemplate()">Круто!</button>
    </div>
    <div class="fonbet">
      <a class="fonbet__logo" href="#">
        <img src="./assets/images/fonbet-2.svg" alt="logo">
      </a>
      <div class="fonbet__flex">
        <button class="fonbet__gift"><img src="./assets/images/gift.svg" alt="logo"></button>
        <button class="fonbet__question"><img src="./assets/images/question.svg" alt="logo"></button>
      </div>
    </div>
  </ng-template>

  <ng-template #secondSlide>
    <div class="onboarding__dark _padding12">
      <div class="onboarding__list">
        <div class="onboarding__item">
          <div class="onboarding__number">1</div>
          <p class="onboarding__text">Проходи уровни быстрее остальных <br> и старайся заработать на каждом <br> как можно больше очков</p>
        </div>
        <div class="onboarding__item">
          <div class="onboarding__number">2</div>
          <p class="onboarding__text">Сделай ставку от <strong>1000 ₽</strong> и коэф. <br> от <strong>1.50</strong> на событие из категории <br> «Футбол» до <strong>30 ноября</strong> 2024.</p>
        </div>
        <div class="onboarding__item">
          <div class="onboarding__number">3</div>
          <p class="onboarding__text">Ищи себя в списке <br> победителей 30 ноября 2024!</p>
        </div>
      </div>
      <table class="onboarding__table">
        <tr>
          <th>Заработано <br> очков</th>
          <th>Сумма <br> фрибетов</th>
        </tr>
        <tr>
          <td>10 000</td>
          <td>1 200 000</td>
        </tr>
        <tr>
          <td>8 000</td>
          <td>800 000</td>
        </tr>
        <tr>
          <td>6 000</td>
          <td>500 000</td>
        </tr>
        <tr>
          <td>4 000</td>
          <td>300 000</td>
        </tr>
        <tr>
          <td>2 000</td>
          <td>200 000</td>
        </tr>
        <tr>
          <td>1 000</td>
          <td>100 000</td>
        </tr>
      </table>
      <button class="btn _red _medium second-slide-btn" (click)="nextTemplate()">Понял</button>
      <p class="onboarding__miniText">*приз будет разделен на равные части между всеми участниками, прошедшими соответствующее
        необходимое количество уровней игры. C подробными условиями можно ознакомиться <a href="#">в правилах акции</a>.</p>
    </div>
  </ng-template>

  <ng-template #thirdSlide>
    <img class="onboarding__longImage" src="./assets/images/onboarding-long-image.png" alt="image">
    <div class="onboarding__dark">
      <img class="onboarding__decor" src="./assets/images/onboarding-decor.png" alt="decor">
      <div class="onboarding__text">Чем быстрее ты пройдешь каждый уровень и чем больше FON-коинов соберешь, тем больше очков заработаешь!
        <br> <br>
        Соревнуйся с другими болельщиками по набранным очкам — Топ-10 получит мерч от Акрон х FONBET!
      </div>
      <div class="onboarding__dark _background">
        <div class="onboarding__textWrapper">
          <div class="onboarding__text">Когда поймешь, что набрал свой максимум за все уровни — нажми на дискету, чтобы зафиксировать результат в таблице лидеров.</div>
          <button class="save-btn"></button>
        </div>
        <a routerLink="/main" class="btn _red _medium" >Круто!</a>
      </div>
    </div>
    <div class="fonbet">
      <a class="fonbet__logo" href="#">
        <img src="./assets/images/fonbet-2.svg" alt="logo">
      </a>
      <div class="fonbet__flex">
        <button class="fonbet__gift"><img src="./assets/images/gift.svg" alt="logo"></button>
        <button class="fonbet__question"><img src="./assets/images/question.svg" alt="logo"></button>
      </div>
    </div>
  </ng-template>
</div>
