import {
	Component,
	ElementRef,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { GameService } from '@services/game.service';
import { ControlsService } from '@services/control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '@services/state.service';

@Component({
	selector: 'app-game-page',
	standalone: true,
	imports: [],
	templateUrl: './game-page.component.html',
	styleUrl: './game-page.component.scss',
})
export class GamePageComponent implements OnInit {
	private route: ActivatedRoute = inject(ActivatedRoute);
	private game: GameService = inject(GameService);
	private state: StateService = inject(StateService);
	@ViewChild('gameContainer', { static: true }) container: ElementRef;
	constructor() {}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			const level = Number(params.get('level')) || 1; // Дефолтное значение 1
			this.state.levelId = level;
			console.log(`Уровень: ${level}`);

			// Передача параметров в GameService
			this.game.container = this.container;
			this.game.prepareGame();
		});
	}
}
