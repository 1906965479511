<div class="save-form">
  <div class="save-form__back">
    <button>< Назад</button>
  </div>
  <form class="save-form__form">
    <div class="save-form__title">Впиши своё имя и номер телефона, <br>
      чтобы участвовать в розыгрыше!</div>
    <div class="save-form__field">
      <div class="save-form__label">ФИО</div>
      <input type="text" class="save-form__input" placeholder="Ваше имя">
    </div>
    <div class="save-form__field">
      <div class="save-form__label">Номер телефона</div>
      <input type="text" class="save-form__input _error" placeholder="+7 ___ ___ __ __">
    </div>
    <div class="checkbox">
      <input id="html2" type="checkbox" />
      <label for="html2"><p>Настоящим даю свое <a href="#">согласие</a> на обработку персональных данных ООО «ФОНКОР», <a href="#">согласие</a> на обработку сведений об оказанных операторами услугах связи и передачу сведений ООО «ФОНКОР», а также подтверждаю, что ознакомлен с <a href="#">правилами</a></p></label>
    </div>
    <a class="btn _red _large" routerLink="/registration">Отправить</a>
  </form>
  <div class="fonbet">
    <a class="fonbet__logo" href="#">
      <img src="./assets/images/fonbet-2.svg" alt="logo">
    </a>
    <div class="fonbet__flex">
      <button class="fonbet__gift"><img src="./assets/images/gift.svg" alt="logo"></button>
      <button class="fonbet__question"><img src="./assets/images/question.svg" alt="logo"></button>
    </div>
  </div>
</div>
