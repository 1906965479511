import { BodyType } from 'matter';
import * as Matter from 'matter';

// export default class Car {
// 	readonly MAX_SPEED = 2;
// 	readonly MAX_SPEED_BACKWARDS = this.MAX_SPEED * 0.35;
// 	readonly ACCELERATION = this.MAX_SPEED / 25;
// 	readonly ACCELERATION_BACKWARDS = this.ACCELERATION * 0.15;
// 	private readonly MAX_ROTATION = Phaser.Math.DegToRad(80); // ~1.396 радиан
// 	bodies: any[] = [];
// 	gas = {
// 		right: false,
// 		left: false,
// 	};
// 	wheelsDown = {
// 		rear: false,
// 		front: false,
// 	};
// 	private _scene: Phaser.Scene;
//
// 	constructor(
// 		scene: Phaser.Scene,
// 		x: number,
// 		y: number,
// 		width: number = 400 / 2,
// 		height: number = 160 / 2,
// 		wheelSize: number = 40 / 2,
// 		wheelOffset: { x: number; y: number } = { x: 78 / 2, y: 82 / 2 }
// 	) {
// 		this._scene = scene;
//
// 		const wheelBase = wheelOffset.x,
// 			wheelAOffset = -width * 0.5 + wheelBase + 16,
// 			wheelBOffset = width * 0.5 - wheelBase,
// 			wheelYOffset = wheelOffset.y;
//
// 		const density = 0.1;
// 		const friction = 1.5;
// 		// @ts-ignore
// 		const Matter = Phaser.Physics.Matter.Matter;
//
// 		let group = scene.matter.world.nextGroup(true);
//
// 		let body = scene.matter.add.image(x, y, 'car');
// 		body.setDisplaySize(width, height);
// 		body.centerOfMass.set(200, 60);
// 		body.setBody(
// 			{
// 				type: 'rectangle',
// 				width: width,
// 				height: height,
// 			},
// 			{
// 				label: 'carBody',
// 				collisionFilter: {
// 					group: group,
// 				},
// 				chamfer: {
// 					radius: height * 0.5,
// 				},
// 				density: 0.1,
// 				restitution: 0,
// 			}
// 		);
// 		let wheelA = scene.matter.add.image(
// 			x + wheelAOffset,
// 			y + wheelYOffset,
// 			'wheel'
// 		);
// 		wheelA.setDisplaySize(wheelSize * 2, wheelSize * 2);
//
// 		wheelA.setBody(
// 			{
// 				type: 'circle',
// 				radius: wheelSize,
// 			},
// 			{
// 				label: 'wheelRear',
// 				collisionFilter: {
// 					group: group,
// 				},
// 				friction,
// 				density,
// 				restitution: 0,
// 			}
// 		);
// 		// wheelA.setScale(0.6);
//
// 		let wheelB = scene.matter.add.image(
// 			x + wheelBOffset,
// 			y + wheelYOffset,
// 			'wheel'
// 		);
// 		wheelB.setDisplaySize(wheelSize * 2, wheelSize * 2);
//
// 		wheelB.setBody(
// 			{
// 				type: 'circle',
// 				radius: wheelSize,
// 			},
// 			{
// 				label: 'wheelFront',
// 				collisionFilter: {
// 					group: group,
// 				},
// 				friction,
// 				density: density * 2.2,
// 				restitution: 0,
// 			}
// 		);
// 		// wheelB.setDisplaySize(wheelSize, wheelSize);
// 		// В классе Car при создании корпуса
// 		body.setMass(5); // Настройте по необходимости
// 		body.centerOfMass.set(0, 100); // Определите центр масс ниже для устойчивости
//
// 		// Установка массы колес
// 		wheelA.setMass(1);
// 		wheelB.setMass(1);
// 		let axelA = scene.matter.add.constraint(
// 			body.body as BodyType,
// 			wheelA.body as BodyType,
// 			0,
// 			1,
// 			{
// 				pointA: { x: wheelAOffset, y: wheelYOffset },
// 			}
// 		);
//
// 		let axelB = scene.matter.add.constraint(
// 			body.body as BodyType,
// 			wheelB.body as BodyType,
// 			0,
// 			1,
// 			{
// 				pointA: { x: wheelBOffset, y: wheelYOffset },
// 			}
// 		);
//
// 		this.bodies = [body.body, wheelA.body, wheelB.body];
// 	}
//
// 	update() {
// 		// @ts-ignore
// 		const Matter = Phaser.Physics.Matter.Matter;
// 		const carBody = this.bodies[0];
// 		const wheelRear = this.bodies[1];
// 		const wheelFront = this.bodies[2];
//
// 		// Ограничение угловой скорости корпуса
// 		// const MAX_ANGULAR_VELOCITY = 1; // Примерное значение, можно настроить
// 		// if (carBody.angularVelocity > MAX_ANGULAR_VELOCITY) {
// 		// 	Matter.Body.setAngularVelocity(carBody, MAX_ANGULAR_VELOCITY);
// 		// } else if (carBody.angularVelocity < -MAX_ANGULAR_VELOCITY) {
// 		// 	Matter.Body.setAngularVelocity(carBody, -MAX_ANGULAR_VELOCITY);
// 		// }
//
// 		// Ограничение угла поворота корпуса
// 		const currentAngle = carBody.angle;
//
// 		if (currentAngle > this.MAX_ROTATION) {
// 			Matter.Body.setAngle(carBody, this.MAX_ROTATION);
// 			Matter.Body.setAngularVelocity(carBody, 0);
// 		} else if (currentAngle < -this.MAX_ROTATION) {
// 			Matter.Body.setAngle(carBody, -this.MAX_ROTATION);
// 			Matter.Body.setAngularVelocity(carBody, 0);
// 		}
//
// 		// Управление газом (вперед/назад)
// 		if (this.gas.right) {
// 			let newSpeed =
// 				wheelRear.angularSpeed <= 0
// 					? this.MAX_SPEED / 10
// 					: wheelRear.angularSpeed + this.ACCELERATION;
// 			if (newSpeed > this.MAX_SPEED) newSpeed = this.MAX_SPEED;
// 			Matter.Body.setAngularVelocity(wheelRear, newSpeed);
// 			Matter.Body.setAngularVelocity(wheelFront, newSpeed);
// 		} else if (this.gas.left) {
// 			let newSpeed =
// 				wheelRear.angularSpeed <= 0
// 					? this.MAX_SPEED_BACKWARDS / 10
// 					: wheelRear.angularSpeed + this.ACCELERATION_BACKWARDS;
// 			if (newSpeed > this.MAX_SPEED_BACKWARDS)
// 				newSpeed = this.MAX_SPEED_BACKWARDS;
//
// 			Matter.Body.setAngularVelocity(wheelRear, -newSpeed);
// 			Matter.Body.setAngularVelocity(wheelFront, -newSpeed);
// 		}
//
// 		// Ограничение скорости машинки
// 		const velocity = carBody.velocity;
// 		const speed = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);
//
// 		const MAX_BODY_SPEED = 10; // Максимальная скорость тела
// 		if (speed > MAX_BODY_SPEED) {
// 			Matter.Body.setVelocity(carBody, {
// 				x: (velocity.x / speed) * MAX_BODY_SPEED,
// 				y: (velocity.y / speed) * MAX_BODY_SPEED,
// 			});
// 		}
// 	}
// }
export default class Car {
	readonly MAX_SPEED = 2.3;
	readonly MAX_SPEED_BACKWARDS = this.MAX_SPEED * 0.35;
	readonly ACCELERATION = this.MAX_SPEED / 25;
	readonly ACCELERATION_BACKWARDS = this.ACCELERATION * 0.15;
	readonly WHEEL_RADIUS: number;
	private startX = 0;
	bodies: any[] = [];
	gas = {
		right: false,
		left: false,
	};
	wheelsDown = {
		rear: false,
		front: false,
	};
	private _scene: Phaser.Scene;

	// Максимальный угол поворота в радианах (80 градусов)
	private readonly MAX_ROTATION = Phaser.Math.DegToRad(60); // ~1.396 радиан

	// Текущие угловые скорости колес
	private currentRearAngularVelocity: number = 0;
	private currentFrontAngularVelocity: number = 0;
	private traveledDistance: number = 0; // Пройденное расстояние

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		width: number = 200, // 400 / 2
		height: number = 80, // 160 / 2
		wheelSize: number = 20, // 40 / 2
		wheelOffset: { x: number; y: number } = { x: 39, y: 41 } // 78 / 2, 82 / 2
	) {
		this.startX = x;
		this._scene = scene;
		this.WHEEL_RADIUS = wheelSize;
		const wheelBase = wheelOffset.x,
			wheelAOffset = -width * 0.5 + wheelBase + 16,
			wheelBOffset = width * 0.5 - wheelBase,
			wheelYOffset = wheelOffset.y;

		const density = 0.02;
		const friction = 1.5; // Увеличенное трение
		// @ts-ignore
		const Matter = Phaser.Physics.Matter.Matter;

		let group = scene.matter.world.nextGroup(true);

		// Создание корпуса машинки
		let body = scene.matter.add.image(x, y, 'car');
		body.setDisplaySize(width, height);
		body.centerOfMass.set(0, 20); // Низкий центр масс для устойчивости
		body.setBody(
			{
				type: 'rectangle',
				width: width,
				height: height,
			},
			{
				label: 'carBody',
				collisionFilter: {
					group: group,
				},
				chamfer: {
					radius: height * 0.5,
				},
				density: density,
				restitution: 0.0, // Отсутствие упругости
				friction: 1.5, // Высокое трение
			}
		);
		body.setMass(5); // Настройте массу корпуса

		// Создание заднего колеса
		let wheelA = scene.matter.add.image(
			x + wheelAOffset,
			y + wheelYOffset,
			'wheel'
		);
		wheelA.setDisplaySize(wheelSize * 2, wheelSize * 2);
		wheelA.setBody(
			{
				type: 'circle',
				radius: wheelSize,
			},
			{
				label: 'wheelRear',
				collisionFilter: {
					group: group,
				},
				friction: 1.5, // Высокое трение
				density: 0.05,
				restitution: 0.0, // Отсутствие упругости
			}
		);
		wheelA.setMass(5); // Уменьшенная масса колес

		// Создание переднего колеса
		let wheelB = scene.matter.add.image(
			x + wheelBOffset,
			y + wheelYOffset,
			'wheel'
		);
		wheelB.setDisplaySize(wheelSize * 2, wheelSize * 2);
		wheelB.setBody(
			{
				type: 'circle',
				radius: wheelSize,
			},
			{
				label: 'wheelFront',
				collisionFilter: {
					group: group,
				},
				friction: 1.5, // Высокое трение
				density: 0.05,
				restitution: 0.0, // Отсутствие упругости
			}
		);
		wheelB.setMass(9); // Уменьшенная масса колес

		// Создание соединений (оси) между корпусом и колесами
		let axelA = scene.matter.add.constraint(
			body.body as BodyType,
			wheelA.body as BodyType,
			0,
			1,
			{
				pointA: { x: wheelAOffset, y: wheelYOffset },
				stiffness: 0.8, // Высокая жесткость
				length: 0, // Нулевая длина
			}
		);

		let axelB = scene.matter.add.constraint(
			body.body as BodyType,
			wheelB.body as BodyType,
			0,
			1,
			{
				pointA: { x: wheelBOffset, y: wheelYOffset },
				stiffness: 0.8, // Высокая жесткость
				length: 0, // Нулевая длина
			}
		);

		this.bodies = [body.body, wheelA.body, wheelB.body];
	}

	update() {
		// @ts-ignore
		const Matter = Phaser.Physics.Matter.Matter;
		const carBody = this.bodies[0];
		const wheelRear = this.bodies[1];
		const wheelFront = this.bodies[2];

		// Ограничение угловой скорости корпуса
		const MAX_ANGULAR_VELOCITY = 1; // Примерное значение, можно настроить
		if (carBody.angularVelocity > MAX_ANGULAR_VELOCITY) {
			Matter.Body.setAngularVelocity(carBody, MAX_ANGULAR_VELOCITY);
		} else if (carBody.angularVelocity < -MAX_ANGULAR_VELOCITY) {
			Matter.Body.setAngularVelocity(carBody, -MAX_ANGULAR_VELOCITY);
		}

		// // Ограничение угла поворота корпуса
		// const currentAngle = carBody.angle;
		//
		// if (currentAngle > this.MAX_ROTATION) {
		// 	Matter.Body.setAngle(carBody, this.MAX_ROTATION);
		// 	Matter.Body.setAngularVelocity(carBody, 0);
		// } else if (currentAngle < -this.MAX_ROTATION) {
		// 	Matter.Body.setAngle(carBody, -this.MAX_ROTATION);
		// 	Matter.Body.setAngularVelocity(carBody, 0);
		// }

		// Плавное управление газом (вперед/назад)
		const accelerationFactor = 0.1; // Настройте этот параметр для контроля скорости изменения

		if (this.gas.right) {
			const targetSpeed = this.MAX_SPEED;
			this.currentRearAngularVelocity = Phaser.Math.Linear(
				this.currentRearAngularVelocity,
				targetSpeed,
				this.ACCELERATION * accelerationFactor
			);
			this.currentRearAngularVelocity = Phaser.Math.Clamp(
				this.currentRearAngularVelocity,
				0,
				this.MAX_SPEED
			);
			this.currentFrontAngularVelocity = this.currentRearAngularVelocity;

			Matter.Body.setAngularVelocity(
				wheelRear,
				this.currentRearAngularVelocity
			);
			Matter.Body.setAngularVelocity(
				wheelFront,
				this.currentFrontAngularVelocity
			);
		} else if (this.gas.left) {
			const targetSpeed = -this.MAX_SPEED_BACKWARDS;
			this.currentRearAngularVelocity = Phaser.Math.Linear(
				this.currentRearAngularVelocity,
				targetSpeed,
				this.ACCELERATION_BACKWARDS * accelerationFactor
			);
			this.currentRearAngularVelocity = Phaser.Math.Clamp(
				this.currentRearAngularVelocity,
				-this.MAX_SPEED_BACKWARDS,
				0
			);
			this.currentFrontAngularVelocity = this.currentRearAngularVelocity;

			Matter.Body.setAngularVelocity(
				wheelRear,
				this.currentRearAngularVelocity
			);
			Matter.Body.setAngularVelocity(
				wheelFront,
				this.currentFrontAngularVelocity
			);
		} else {
			// Плавное замедление колес
			const dampingFactor = 0.95; // Настройте этот параметр
			this.currentRearAngularVelocity *= dampingFactor;
			this.currentFrontAngularVelocity *= dampingFactor;

			Matter.Body.setAngularVelocity(
				wheelRear,
				this.currentRearAngularVelocity
			);
			Matter.Body.setAngularVelocity(
				wheelFront,
				this.currentFrontAngularVelocity
			);
		}

		// Ограничение скорости машинки
		const velocity = carBody.velocity;
		const speed = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);

		const MAX_BODY_SPEED = 10; // Максимальная скорость тела
		if (speed > MAX_BODY_SPEED) {
			Matter.Body.setVelocity(carBody, {
				x: (velocity.x / speed) * MAX_BODY_SPEED,
				y: (velocity.y / speed) * MAX_BODY_SPEED,
			});
		}

		// Плавное замедление линейной скорости
		const dampingFactor = 0.98; // Настройте этот параметр
		Matter.Body.setVelocity(carBody, {
			x: carBody.velocity.x * dampingFactor,
			y: carBody.velocity.y * dampingFactor,
		});
	}

	/**
	 * Возвращает текущую скорость автомобиля, основанную на вращении заднего колеса.
	 * @returns Текущая скорость в пикселях в секунду.
	 */
	public getSpeed(): number {
		const wheelRear = this.bodies[1];
		const angularVelocity = wheelRear.angularVelocity; // радиан/сек
		return angularVelocity * this.WHEEL_RADIUS; // пикселей/сек
	}

	/**
	 * Возвращает текущую позицию автомобиля.
	 * @returns Вектор позиции автомобиля.
	 */
	public getPosition(): Phaser.Math.Vector2 {
		const carBody = this.bodies[0];
		return new Phaser.Math.Vector2(carBody.position.x, carBody.position.y);
	}
}
