import { Injectable } from '@angular/core';
import { User } from '@interfaces/user.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class StateService {
	private _user: User;
	private _levelId$ = new BehaviorSubject<number>(1);
	constructor() {}

	public get levelId$(): Observable<number> {
		return this._levelId$.asObservable();
	}

	public get levelId(): number {
		return this._levelId$.getValue();
	}

	public set levelId(value: number) {
		this._levelId$.next(value);
	}

	public get user(): User {
		return this._user;
	}

	public set user(value: User) {
		this._user = value;
	}

	public incrementLevelId(): void {
		if (this._levelId$.getValue() < 4) {
			this._levelId$.next(this._levelId$.getValue() + 1);
		}
	}
}
