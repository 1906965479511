import Terrain from '@src/app/ts/models/terrain-dynamic';
import Car from '@src/app/ts/models/car';
import Coin from '@src/app/ts/models/coin';
import { GameNames } from '@enums/game.enum';

export default class MainScene extends Phaser.Scene {
	car: Car;
	terrain: Terrain;
	public coins: Coin[] = []; // Массив для хранения монеток
	public numberOfCoins = 30;

	constructor() {
		super({ key: 'MainScene' });
	}

	create() {
		const sprite = this.game.registry.get(GameNames.SPRITE);
		const level = this.game.registry.get(GameNames.LEVEL);
		this.terrain = new Terrain(this, sprite, 280, 0, level);
		this.car = new Car(this, 490, 100);
		// check of the rear wheels are touching the ground
		this.matter.world.on(
			'collisionactive',
			(collisions: any, b: any, c: any) => {
				this.car.wheelsDown = { rear: false, front: false };
				collisions.pairs.forEach((pair: any) => {
					let labels: string[] = [pair['bodyA'].label, pair['bodyB'].label];
					if (labels.includes('wheelRear')) {
						this.car.wheelsDown.rear = true;
					}
					if (labels.includes('wheelFront')) {
						this.car.wheelsDown.front = true;
					}
				});
			}
		);

		const wallThickness = 50;

		// Левая стенка
		this.matter.add.rectangle(220, 400, wallThickness, 700, {
			isStatic: true,
			label: 'leftWall',
		});

		// hide the loading screen
		let loadingScreen = document.getElementById('loading-screen');
		if (loadingScreen) loadingScreen.style.display = 'none';
		this.events.emit('sceneReady', this);
		this.input.keyboard.on('keydown', (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') {
				this.car.gas.left = true;
			}
			if (event.key === 'ArrowRight') {
				this.car.gas.right = true;
			}
		});
		this.input.keyboard.on('keyup', (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') {
				this.car.gas.left = false;
			}
			if (event.key === 'ArrowRight') {
				this.car.gas.right = false;
			}
		});
		// Создание монеток
		const numberOfCoins = 10; // Количество монеток
		const deltaY = 50; // Смещение по Y над дорогой
		this.createCoins(this.numberOfCoins, deltaY);
		this.setCameraBounds();
	}
	/**
	 * Устанавливает границы камеры на основе границ дороги
	 */
	private setCameraBounds(): void {
		// Если вы хотите, чтобы камера была ограничена только по горизонтали, можно установить вертикальные границы в 0
		this.cameras.main.setBounds(
			this.terrain.background.x,
			this.terrain.background.y - 150,
			this.terrain.background.width,
			this.terrain.background.height,
			true
		);
		console.log(this.terrain.background.y);
	}
	override update() {
		this.terrain.update();

		// fix the camera to the car
		const carBody = this.car.bodies[0];
		this.cameras.main.centerOn(carBody.position.x, carBody.position.y + 100);
		console.log(this.cameras.main.height);
		let zoom = 0.35;
		if (this.cameras.main.height < 800) {
			zoom = 0.35;
		} else {
			zoom = 0.4;
		}
		this.cameras.main.setZoom(zoom);
		this.car.update();
	}
	/**
	 * Возвращает экземпляр Terrain.
	 * @returns Экземпляр Terrain.
	 */
	public getTerrain(): Terrain {
		return this.terrain;
	}

	private createCoins(numberOfCoins: number, deltaY: number): void {
		const vertices = this.terrain.normalizedVertices;
		const totalPoints = vertices.length;
		const spacing = Math.floor(totalPoints / numberOfCoins);

		for (let i = 0; i < numberOfCoins; i++) {
			const index = i * spacing;
			if (index < totalPoints) {
				const vertex = vertices[index];
				// Добавление случайного смещения по X и Y для естественности
				const randomOffsetX = Phaser.Math.Between(-20, 20);
				const randomOffsetY = Phaser.Math.Between(-10, 10);

				// Корректное смещение по Y и добавление позиции дороги
				const coinX = this.terrain.x + vertex.x + randomOffsetX;
				const coinY =
					this.terrain.y +
					vertex.y -
					this.terrain.padding +
					randomOffsetY -
					deltaY;

				const coin = new Coin(this, coinX, coinY, deltaY);
				this.coins.push(coin);
			}
		}

		// Добавление обработчика сбора монеток
		this.matter.world.on('collisionstart', this.handleCollision, this);
	}

	/**
	 * Обработчик столкновений между машинкой и монетками.
	 * @param event Событие столкновения.
	 */
	private handleCollision(event: any): void {
		event.pairs.forEach((pair: any) => {
			const { bodyA, bodyB } = pair;

			// Проверяем, включает ли одно из тел машинку, а другое — монетку
			const carBody = this.car.bodies[0];
			let coin: Coin | null = null;

			if (bodyA === carBody) {
				coin = this.findCoinByBody(bodyB);
			} else if (bodyB === carBody) {
				coin = this.findCoinByBody(bodyA);
			}

			if (coin) {
				coin.collect();
				// Удаляем монетку из массива
				this.coins = this.coins.filter(c => c !== coin);
				// Добавьте здесь логику для увеличения счётчика монеток или других действий
				console.log('Монетка собрана!');
			}
		});
	}

	/**
	 * Находит монетку по телу физики.
	 * @param body Тело физики для поиска.
	 * @returns Найденная монетка или null.
	 */
	private findCoinByBody(body: MatterJS.BodyType): Coin | null {
		for (const coin of this.coins) {
			if ((coin.sprite.body as MatterJS.BodyType) === body) {
				return coin;
			}
		}
		return null;
	}
}
