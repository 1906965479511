import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	inject,
	signal,
} from '@angular/core';
import { CityItem } from '@interfaces/city-item.interface';
import { NgOptimizedImage } from '@angular/common';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { RouterLink } from '@angular/router';
import { StateService } from '@services/state.service';

@Component({
	selector: 'app-main-page',
	standalone: true,
	imports: [NgOptimizedImage, SwiperDirective, RouterLink],
	templateUrl: './main-page.component.html',
	styleUrl: './main-page.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainPageComponent {
	private modal = inject(ModalService);
	private state = inject(StateService);
	public cityIndex = signal(0);
	public cities: CityItem[] = [
		{
			id: 1,
			name: 'Самара',
			directionName: 'Самару',
			image: './assets/images/city-samara.png',
			description: `Первой остановкой для ЛамПАЗа станет стадион «Солидарность Самара Арена», где ФК «Акрон» встретится с ФК «Крылья Советов»! Едем только ради победы!`,
		},
		{
			id: 2,
			name: 'Казань',
			directionName: 'Казань',
			image: './assets/images/city-kazan.png',
			description: `Продолжаем наше Волжское турне! ЛамПАЗ мчится в один из самых древних и красивых городов России. Следующая остановка — «Ак Барс Арена», где нас ждёт матч с ФК «Рубин». Жми на газ!`,
		},
		{
			id: 3,
			name: 'Ростов-на-Дону',
			directionName: 'Ростов-на-Дону',
			image: './assets/images/city-rostov.png',
			description: `Мы на полпути! Держите руль крепче. Теперь ЛамПАЗ отправляется в путешествие до «Ростов Арены», чтобы одержать победу над ФК «Ростов». Команда рассчитывает на вас!`,
		},
		{
			id: 4,
			name: 'Москва',
			directionName: 'Москву',
			image: './assets/images/city-moscow.png',
			description: `Мчим ЛамПАЗ покорять столицу! Следующая остановка для ФК «Акрон» — «ВЭБ Арена», где нас уже ждёт матч с ФК ЦСКА. Выкладываемся на максимум!`,
		},
		{
			id: 5,
			name: 'С.Петербург',
			directionName: 'С.Петербург',
			image: './assets/images/city-spb.png',
			description: `Финальной остановкой в маршруте ЛамПАЗа станет  культурная столица России, где мы на «Газпром Арене» сразимся с ФК «Зенит». Покажем им настоящий футбол родом из Тольятти!`,
		},
	];

	changeCityName(index: number) {
		this.cityIndex.set(index);
	}

	openModal() {
		this.modal.openModal(ModalType.LEVEL_FINISHED);
	}
}
