import { Type } from '@angular/core';
import { ModalLevelFinishComponent } from '@modal/components/modal-level-finish/modal-level-finish.component';
import { ModalLivesDescriptionComponent } from '@modal/components/modal-lives-description/modal-lives-description.component';
import { ModalCloseGameComponent } from '@modal/components/modal-close-game/modal-close-game.component';
import { ModalPauseComponent } from '@modal/components/modal-pause/modal-pause.component';
import { ModalAttemptsComponent } from '@modal/components/modal-attempts/modal-attempts.component';
import { ModalLevelFailedComponent } from '@modal/components/modal-level-failed/modal-level-failed.component';
import { ModalAllLevelsCompleteComponent } from '@modal/components/modal-all-levels-complete/modal-all-levels-complete.component';
import { ModalLoaderComponent } from '@modal/components/modal-loader/modal-loader.component';
import { ModalGameOnboardingComponent } from '@modal/components/modal-game-onboarding/modal-game-onboarding.component';

export enum ModalType {
	// пауза игры
	PAUSE = 'PAUSE',
	// выход с онбординга
	// игровой онбоардинг
	GAME_ONBOARDING = 'GAME_ONBOARDING',
	// описание жизней
	LIVES_DESCRIPTION = 'LIVES_DESCRIPTION',
	// выход игры
	CLOSE_GAME = 'CLOSE_GAME',
	//успешное завершение уровня
	LEVEL_FINISHED = 'LEVEL_FINISHED',
	//успешное завершение всех уровней
	ALL_LEVELS = 'ALL_LEVELS',
	//безуспешное завершение уровня
	LEVEL_FAILED = 'LEVEL_FAILED',
	//не осталось попыток
	ATTEMPTS = 'ATTEMPTS',
	//прелоадер
	LOADER = 'LOADER',
}

export type ModalComponentUnion =
	| ModalPauseComponent
	| ModalLivesDescriptionComponent
	| ModalGameOnboardingComponent
	| ModalCloseGameComponent
	| ModalLevelFailedComponent
	| ModalLoaderComponent
	| ModalAttemptsComponent
	| ModalLevelFinishComponent;

export const modalTypeMapper: Record<ModalType, Type<ModalComponentUnion>> = {
	[ModalType.PAUSE]: ModalPauseComponent,
	[ModalType.GAME_ONBOARDING]: ModalGameOnboardingComponent,
	[ModalType.LIVES_DESCRIPTION]: ModalLivesDescriptionComponent,
	[ModalType.CLOSE_GAME]: ModalCloseGameComponent,
	[ModalType.LEVEL_FINISHED]: ModalLevelFinishComponent,
	[ModalType.ALL_LEVELS]: ModalAllLevelsCompleteComponent,
	[ModalType.LEVEL_FAILED]: ModalLevelFailedComponent,
	[ModalType.ATTEMPTS]: ModalAttemptsComponent,
	[ModalType.LOADER]: ModalLoaderComponent,
};
