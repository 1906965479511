import { Component, inject, Input } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';
import SceneManager = Phaser.Scenes.SceneManager;
import { Game } from 'phaser';
import { GameService } from '@services/game.service';
import { StateService } from '@services/state.service';

@Component({
	selector: 'app-modal-pause',
	templateUrl: './modal-pause.component.html',
	styleUrl: './modal-pause.component.scss',
	standalone: true,
})
export class ModalPauseComponent extends ModalBaseComponent {
	@Input() data: Game;
	private game: GameService = inject(GameService);
	private state: StateService = inject(StateService);
	restart(): void {
		// const mainScene = this.data.scene.getScene('MainScene');
		// const guiScene = this.data.scene.getScene('GuiScene');
		// mainScene.scene.restart();
		// guiScene.scene.restart();
		// mainScene.game.resume();
		// guiScene.game.resume();
		this.game.prepareGame();
		if (this.state.levelId !== 1) {
			this.closeModal();
		}
	}

	resume(): void {
		const mainScene = this.data.scene.getScene('MainScene');
		const guiScene = this.data.scene.getScene('GuiScene');
		mainScene.game.resume();
		guiScene.game.resume();
		this.closeModal();
	}

	closeGame() {
		this.data.destroy(true);
		this.closeAndNavigate('/main');
	}
}
