import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	signal,
	ViewChild,
} from '@angular/core';
import { ModalBaseComponent } from '@modal/components/modal-base/modal-base.component';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { SwiperDirective } from '@src/app/directives/swiper.directive';
import { GameOnboardingItem } from '@interfaces/game-onboarding-item.interface';
import { Game } from 'phaser';
import GuiScene from '@src/app/ts/scenes/gui-scene';

@Component({
	selector: 'app-modal-game-onboarding',
	standalone: true,
	imports: [NgOptimizedImage, SwiperDirective, NgClass],
	templateUrl: './modal-game-onboarding.component.html',
	styleUrl: './modal-game-onboarding.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalGameOnboardingComponent extends ModalBaseComponent {
	@Input() data: Game;
	public slideIndex = signal<number>(0);
	public slides: GameOnboardingItem[] = [
		{
			id: 1,
			image: 'https://placehold.co/600x400',
			description: `Уже готов сесть за руль ЛамПАЗа и привезти ФК «Акрон» к победе? Отлично! Тебя ждёт путь до пяти стадионов в разных городах России. Не сбавляй газу, но и FON-коины не пропускай — так ты соберешь больше всего очков!`,
		},
		{
			id: 2,
			image: 'https://placehold.co/600x400',
			description: `С маршрутом разобрались. Теперь к управлению! Нажимай на правую педаль, чтобы набрать скорость ЛамПАЗА или левую для остановки и заднего хода. А спидометр по центру поможет тебе определить, как быстро едет наш автобус и подготовиться к опасностям на дороге.`,
		},
		{
			id: 3,
			image: 'https://placehold.co/600x400',
			description: `Следи за индикаторами сверху экрана, чтобы понять, как долго ещё ехать и сколько FON-коинов уже собрано. Очки начисляются в конце каждого уровня исходя из затраченного времени и кол-ва полученных монет. Неудачное начало? Нажми кнопку «Restart» и начни сначала!`,
		},
	];
	@ViewChild(SwiperDirective) slider: SwiperDirective;

	public async navigateTo() {
		if (this.slideIndex() !== this.slides.length - 1) {
			this.slider.swiper.slideNext();
			return;
		}
		console.log(this.data);
		this.data.resume();
		const guiScene: GuiScene = this.data.scene.getScene('GuiScene') as GuiScene;
		guiScene.startTimer();
		this.closeModal();
	}

	public changeIndex(index: number) {
		this.slideIndex.set(index);
	}
}
