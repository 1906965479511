import { Component } from '@angular/core';

@Component({
  selector: 'app-congratulations-page',
  standalone: true,
  imports: [],
  templateUrl: './congratulations-page.component.html',
  styleUrl: './congratulations-page.component.scss'
})
export class CongratulationsPageComponent {

}
