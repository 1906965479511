import { ApplicationConfig } from '@angular/core';
import {
	PreloadAllModules,
	provideRouter,
	withHashLocation,
	withPreloading,
} from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { tokenInterceptor } from '@services/token.interceptor';

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(
			routes,
			withHashLocation(),
			withPreloading(PreloadAllModules)
		),
		provideHttpClient(withInterceptors([tokenInterceptor])),
		provideAnimations(),
	],
};
